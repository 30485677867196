<template>
    <div class="page-game-player-menu flex-center">
        <router-view />
    </div>
</template>
<script>
    export default {
        name: '',
        props: {
        },
        data() {
            return {};
        },
        methods: {},
        created() {
        },
        components: {},
        computed: {},
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less">
    .page-game-player-summary{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        .player-sum-item{
            width: 150px;
            height: 150px;
        }
        .player-sum-item.taste{
            background: #6F83DE;
        }
        .player-sum-item.invite{
            background: #F9BD6B;
        }
        // .player-sum-item+ .player-sum-item{
        //     margin-left: 20px;
        // }
    }
</style>